import React from "react";
import styled from "styled-components";

import BrandsWeOwn from "../home/brands-we-own";

import Wrapper from "../../elements/wrapper";
import BrandsWeWorkWith from "../home/brands-we-work-with";
import CategoryGrid from "./category-grid";

const ProductIndexContainer = styled.div`
  padding: 70px 0;

  .page-title {
    text-align: center;
    padding-bottom: 70px;
    h1 {
      margin: 0;
      text-transform: uppercase;
      font-weight: 900;
      color: #222;
      font-size: 40px;
    }
  }
`;

const ProductIndex = ({ data }) => {
  const page = data?.productIndex?.edges[0]?.node || {};
  const { pageTitle, brandsWeOwn, brandsWeWorkWith, productTiles } =
    page?.product_index_acf || {};
  return (
    <ProductIndexContainer>
      <Wrapper className="product-index-wrapper">
        <div className="page-title">
          <h1>{pageTitle}</h1>
        </div>
        <CategoryGrid settings={productTiles} />
        {/* <BrandsWeOwn settings={brandsWeOwn} />
        <BrandsWeWorkWith settings={brandsWeWorkWith} /> */}
      </Wrapper>
    </ProductIndexContainer>
  );
};

export default ProductIndex;
