import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { WebsiteContext } from "../context/WebsiteContext";
import ProductIndex from "../components/page-components/product-index";

const ProductIndexPageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const ProductIndexPage = ({ data }) => {
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title="Products" />
      <ProductIndexPageContainer distanceFromTop={distanceFromTop}>
        <ProductIndex data={data} />
      </ProductIndexPageContainer>
    </Layout>
  );
};

export default ProductIndexPage;

export const query = graphql`
  query {
    productIndex: allWpPage(filter: { title: { eq: "Product Index" } }) {
      edges {
        node {
          product_index_acf {
            pageTitle
            productTiles {
              tileLink {
                title
                url
              }
              tileImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
