import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const CategoryGridContainer = styled.div`
  /* margin: 0 auto;
  ${media.large`max-width: 1200px;`} */
  .grid-wrapper {
    text-align: center;

    ${media.small`display: flex;flex-wrap: wrap; margin: 0 -15px;`}

    .item-container {
      padding: 0 15px;
      margin-bottom: 30px;
      &:nth-child(-n + 3) {
        flex: 0 0 33.33%;
      }
      flex: 0 0 50%;

      .inner-wrap {
        a {
          text-decoration: none;
        }
        .image-container {
          max-height: 300px;
          margin: 0 auto;
          overflow: hidden;
          position: relative;

          .overlay {
            background-color: rgb(0 0 0 / 45%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;

            h3 {
              color: #fff;
              margin: 0 auto;
              font-size: 40px;
              font-weight: 900;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
`;

const CategoryGrid = ({ settings }) => {
  let cols = settings || [];

  return (
    <CategoryGridContainer>
      <Wrapper className="grid-wrapper">
        {cols.map(col => {
          const { tileLink, tileImage } = col || {};
          let image = getImage(tileImage?.localFile);
          return (
            <div className="item-container">
              <div className="inner-wrap">
                <Link to={tileLink.url}>
                  <div className="image-container">
                    <GatsbyImage
                      alt={tileLink.title + " image"}
                      image={image}
                    />
                    <div className="overlay">
                      <h3>{tileLink.title}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </Wrapper>
    </CategoryGridContainer>
  );
};

export default CategoryGrid;
